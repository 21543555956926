.invite-play {
    color:green !important;
}

.invite-pause {
    color:red !important;
}

.invite-fafa-size {
    font-size: 20px;
}