.customer-details .card .header {
    padding: 10px !important;
    background: #eceff1;
}
.customer-details > .container-fluid > .row{
    display: flex;
    flex-wrap: wrap;
}
.customer-details > .container-fluid > .row>*{
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
}
@media (min-width: 992px){
    .customer-details > .container-fluid > .row .col-md-6 {
        width: 50%;
    }
}
.customer-details > .container-fluid > .row .card{
    height: calc(100% - 30px);
}

.customer-details > .container-fluid > .row .card h5 span{
    width: 140px;
    display: inline-block;
    border-right: 1px dashed #ccc;
    margin-right: 15px;
    color: #000;
    font-weight: bold;
}

.que-ansTable td {
    border-right: 10px solid #fff;
    border-top: 1px solid #fff !important;
    border-radius: 0px !important;
    padding: 10px !important;
}
.treatments-count{
    margin-top: -34px;
    position: absolute;
    font-weight: bold !important;
    color: #000000 !important;
}
.users-notification{
    float: right;
}
.custom-btn.edit-btn {
    margin-top: -30px !important;
    padding: 3px 10px;
}
.btn-success.btn-small{
    padding: 3px 10px;
}

.btn-danger.btn-small{
    padding: 3px 10px;
}



.notification-button{
    display: inline-block;
    height: 40px;
    width: 100%;
}
.custom-btn{
    margin: 0px 10px !important;
    float: left !important;
}
.notification-button .custom-btn, .card .header .custom-btn{
    float: right !important;
}
.notification-button .btn{
    margin-left: 2px;
}

.users-back-btn {
    margin: 0px 0px 7px 14px !important;
}
label, .react-datepicker-wrapper{
    width: 100%;
}

/* size csss */

.react-datepicker {
    font-size: 1.3rem !important;
  }
  
  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }
  
  .react-datepicker__header {
    padding-top: 6px !important;
  }
  
  .react-datepicker__navigation {
    top: 13px !important;
  }
  
  .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
  }
.Diagnosis-form .btn-fill.pull-right{
    
    margin-left: 3px;
    display: inline-block;
  
}
.ck-editor img{
    max-width: 300px !important;
}

.diagnosis-time .time {
    width: 200px;
    float: right;
    top: 15px;
    position: absolute;
    right: 0px;
    z-index: 9;
    background: transparent;
    border-radius: 0px;
}
.diagnosis-time .form-group{
    position: relative;
}
.diagnosis-time .help-block{
    position: absolute;
    right: 115px;
    top: 55px;
}
.shipment-danger {
    background-color:#FFE8E8!important;
}

.users-list-fafa {
    width: 100px;
}

.users-list-fafa i {
    font-size: 20px!important;
    color: #000000;
}

.users-list-fafa img {
    height: 20px;
    width: 20px;
}

/* .card .table thead th:last-child, .card .table thead td:last-child {
    width: auto !important;
} */

/* End size csss */

.label_wrap{
    padding-left: 5px;
}
.childWrap{
    margin-left: 10px;
    padding: 2px;
}
.wrap_border{
    border: 1px solid #d5d5d5;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}
.wrap_border .block_text_div:not(:nth-of-type(1)){
    border-top: 1px solid #DFE0EB;
}
.border_bottom{
    border-bottom: 1px solid #F7F7F8;
}

.border_bottom select{
background-color: #F7F7F8;

}
.wrap_border_product{
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 20px;
    background: #fff;
    margin-top: 20px;
}
.wrap_border_product1{
    /*margin-left: 5px;*/
    display: flex;
    align-items: center;
    padding: 6px 0;
}
.product_check {
    flex: 1 0 0;
    padding: 0 15px;
}
.product_treatment {
    width: 215px;
}
.product_time {
    width: 130px;
    padding: 0 15px;
}
.product_frequency {
    width: 190px;
    padding-right: 15px;
}
.product_area {
    width: 464px;
    padding: 0 15px 6px 0;
    margin-left: auto;
    min-height: 40px;
}
.product-title {
    margin-bottom: 5px;
    text-align: center;
}
.product_treatment .product-title {
    margin-left: 65px;
}
@media (max-width: 992px) {
    .wrap_border_product1{
        display: block;
    }
    .wrap_border_product1 > div{
        margin-bottom: 12px;
    }
    .product_treatment {
        width: 100%;
        padding: 0 15px;
    }
    .product_time {
        width: 100%;
    }
    .product_frequency {
        width: 100%;
        padding: 0 15px;
    }
    .product_area {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 10px;
    }
}
.add_wrap .col-md-6 {
    padding-right: 0px!important;
}

#list-datatable thead {
    background: #fff;
}

label.block_heading.control-label {
    font-size: 18px;
    color: #000;
    text-transform: capitalize;
}
.block_text {
    padding: 5px 0;
}

.block_text input[type="radio"] {
    color: #000000;
}

label.template_type.label_wrap, .template_type.label_wrap {
    color: #000;
    text-transform: capitalize;
    font-size: 14px;
}
.wrap_border.add_wrap_block {
    margin-top: -80px;
}

.skincare_block {
    background: #fff;
}

.skincareContent iframe{
    width: 100%;
    height: 100%;
    border: 0;
    min-height: 460px;
}

.skincareContent .iframe{
    width: 100%;
    height: 100%;
    border: 0;
    min-height: 460px;
    padding: 0 10px 40px;
    overflow-y: auto;
}

.skincareContent {
    margin-top: 16px;
    padding: 10px;
    flex: 1 1;
    height: 100%;
    background: #fff;
}

.wrap_border.addi {
    margin-top: 0px;
}
.additionl-text{
    margin-bottom: 30px;
    padding: 10px;
    flex: 1 1;
    background: #fff;
}
.table-responsive-important{
    overflow-x: auto;
}
.skincare_block_wrapper{
    width: 420px;
    height: 850px;
    position: relative;
    margin: auto;
}
.skincare_block_wrapper .phone-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}
.skincare_block_wrapper .phone-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.skincare_block{
    width: 100%;
    height: 106%;
    overflow: hidden;
    border-radius: 60px;
    transform: scale(0.90);
    padding: 50px 15px 15px;
    position: absolute;
    top: -30px;
    left: 0;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1200px) {
    .skincare_block_wrapper{
        width: 250px;
        height: 505px;
    }
    .skincare_block {
        width: 120%;
        height: 119%;
        border-radius: 30px;
        transform: scale(0.80);
        padding: 28px 15px 15px;
        top: -48px;
        left: -25px;
    }
}

.customer-details > .container-fluid > .row .card h5 span {
    width: 140px;
    display: inline-block;
    border-right: 1px dashed #ccc;
    margin-right: 15px;
    color: #000;
    font-weight: bold;
    vertical-align: top;
}

.customer-details > .container-fluid > .row .card h5 .space1 {
       margin-right: 15px;
        color: #000;
        font-weight: normal;
        display: inline-block;
        font-size: 14px;
        overflow: hidden;
        text-align: left;
        width: 250px;

}
.card .title {
    word-break: break-word;
}
.card .centered_label_checkbox{
    color: #000;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    align-items: flex-start;
    width: auto;
}
.card .centered_label_checkbox span{
    margin-left: 16px;
}
.card .centered_label_checkbox input[type=checkbox]{
    margin: 3px 0 0;
}
.card .mustaches_group{
    border-bottom: 1px solid #DFE0EB;
    padding: 5px 0;
    margin-bottom: 15px;
}
.card .mustaches_group:last-of-type{
    border: 0;
}
.card .mustaches_group .mustaches_group_title{
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7 7L13 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 18px);
    background-position-y: 50%;
    padding-right: 35px;
}
.card .mustaches_group.opened .mustaches_group_title{
    background-image: url("data:image/svg+xml;utf8,<svg transform='rotate(180)' width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7 7L13 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}
.card .mustaches_group .mustaches_group_title .centered_label_checkbox{
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
}
.card .mustaches_group .mustaches_group_body{
    height: 0;
    overflow: hidden;
}
.card .mustaches_group .padding{
    padding-left: 30px;
}
.card .mustaches_group.opened .mustaches_group_body{
    height: auto;
}
/*mustache styles*/
.skincareContent #accordion{
    padding:0px;
    font-family:'SF Pro Display';
}
.skincareContent .card{
    margin-bottom:12px;
    border:none;
    border-radius:6px;
    overflow:hidden;
}
.skincareContent .card-header{
    background:#FEF9F6;
    font-size:18px;
    line-height:18px;
    letter-spacing:-0.08px;
    color:#311D35;
    border:none;
    padding:23px 10px;
    font-weight:600;
    font-family:'New York Medium',sans-serif;
}
.skincareContent .card-body{
    background:#ffffff;
    padding:5px 5px 10px;
    font-weight:300;
    font-size:16px;
    line-height:24px;
    letter-spacing:-0.24px;
    color:#574558;
    font-family:"SF Pro Display";
    font-weight:400;
}
.skincareContent .rotate-icon{
    color:#a3a3a3;
    font-size:14px;
    line-height:inherit;
}
.skincareContent .card-header:not(.collapsed) .rotate-icon{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
}
.skincareContent .product_title{
    font-family:'New York Medium',sans-serif;
}
.skincareContent p{
    font-family:"SF Pro Display";
    font-weight:400;
}
.skincareContent .card-body td{
    width:50%;
}
.skincareContent .card-body img{
    width:100%;
    border-radius:6px;
    margin-bottom: 12px;
}
.skincareContent .card-body video{
    width: 100%;
    border-radius: 6px;
    margin-bottom: 12px;
}
.skincareContent .picture_slider{
    margin-bottom:12px;
    height:50vh;
}
.skincareContent .picture_slider div img{
    height:100%;width:auto;
    max-width:100%;
    margin:0 auto;
    border-radius:6px;
    object-fit:cover;
}
.skincareContent .picture_slider video{
    width: 100%;
    border-radius: 6px;
    margin-bottom: 12px;
}
.skincareContent .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.skincareContent .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.skincareContent .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.skincareContent .slick-dots li button:before{
    font-family: 'slick';
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing:grayscale;
}
.skincareContent .slick-dots li.slick-active button:before{
    opacity: .75;color:black;
}
.skincareContent td{
    vertical-align: top;
}
/*END mustache styles*/
.additional_option{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-decoration-line: underline;
    color: #871C27;
    margin-bottom: 18px;
    cursor: pointer;
}
.block_subheading{
    border-top: 1px solid #DFE0EB;
    padding: 15px 0 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.08px;
    text-transform: capitalize;
    color: #311D35;
    display: flex;
}
.additional_border{
    padding: 15px 0 10px;
    border-top: 1px solid #DFE0EB;
}
.checkbox_switch{
    margin-left: auto;
    padding-left: 10px;
}

.answer-scroll-block {
    max-height: 506px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}

.answer-scroll-block > div:first-of-type > hr {
    margin-top: 0;
}

.assessment-field {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.assessment-title {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 5px;
    margin-right: 15px;
    width: 140px;
    min-width: 140px;
}

.assessment-variants {
    display: flex;
    flex-wrap: wrap;
}

.assessment-label {
    width: auto !important;
    color: #333 !important;
    margin-right: 15px;
    text-transform: capitalize !important;
    cursor: pointer;
}

.assessment-textarea {
    resize: vertical;
    margin-top: 5px;
}

.assessment-textarea-title {
    text-transform: capitalize !important;
    color: #333 !important;
    display: block;
    margin-bottom: 5px;
}

.assessment-success {
    position: fixed !important;
    top: 30px;
    right: 30px;
    color: #333 !important;
    z-index: 20;
    width: 200px;
}
.assessment-error {
    position: fixed !important;
    top: 30px;
    right: 30px;
    z-index: 20;
    width: 200px;
}