//@font-face {
//  font-family: "New York Extra Large";
//  src: url("../font/NewYorkExtraLargeRegular.otf") format("opentype");
//}
//@font-face {
//  font-family: "New York Medium";
//  src: url("../font/NewYorkMediumRegular.otf") format("opentype");
//}
//@font-face {
//  font-family: "SF Pro Display";
//  src: url("../font/SFProDisplay.ttf") format("truetype");
//}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
.MainCusBody {
  background: #f6ede8;
}
.Sign_Home {
  max-width: 100%;
  height: 100vh;
  background: #f6ede8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.main-Section-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_Box {
  width: 505px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text-Custom::placeholder{
  color: #a3a3a3;
}
.form-section {
  max-width: 505px;
  width: 100%;
  margin-top: 16px;
}
.custm-angle {
  position: absolute;
  top: 0px;
  width: 100%;
  display: none;
  padding: 15px 24px;
  background: #fff;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.text-Custom {
  width: 303px;
  max-width: 100%;
  height: 50px;
  background: transparent;
  border-radius: 6px;
  border: 2px solid #a3a3a3;
}
.main_txt {
  font-family: 'New York Extra Large';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 0;
  text-align: center;
  color: #311d35;
  margin-top: 33.3px;

}
.sec_text {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
  font-style: normal;
  color: #000;
  font-family: "SF Pro Display";
  display: block;
  text-align: center;

}
.custom-btn-web {
  width:175px;
  height: 50px;
  background: #92001a !important;
  border-radius: 6px;
  color: #fff;
  font-family: 'New York Medium';
  font-weight: 400;
  border: none;
  text-align: center;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-btn-web:hover {
  color: #fff;
}
.main-logo{
  width: 100%;
  margin: auto;
}
.main-logo img{
  width: 187px;
}
.padding-0 {
  padding-right: 20px;
  padding-left: 20px;
}
.form-section form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.MobileText{
  display: block;
}
.alert {
  color: #d6062b;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 25px;
  /* display: none; */
}

.copy {
  position: absolute;
  bottom: 10px;
  color: #92001a;
  font-size: 12px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}
.form-control:focus {
  border-color: rgba(146, 0, 26, 0.871) !important;
  box-shadow: 0 0 0 0.2rem rgba(196, 105, 122, 0.849);
}

/* ============================================SignUp================== */

.img-box {
  width: 100%;
  margin: 0;
}

.img-box .imgWrap {
  width: 100px;
  height: 100px;
}
.img-box img {
  width: 100%;
}
.main_txt_sign {
  font-family: "New York Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: #311d35;
}
.signUp_text {
  font-family: "SF Pro Display";
  font-size: 20px;
}
.alrt_txt1 {
  color: #574558;
  font-size: 20px;
}
.img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_Box {
  align-items: unset;
}
.continue-btn {
  width: 303px;
  height: 50px;
  /* margin-left: 10px; */
  background: #92001a !important;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  font-family: "New York Extra Large";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  border: none;
  margin-top: 30px;
}

/* ============================================SignIn================== */

.formOTP {
  display: flex;

  flex-flow: column;
}
.formItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
}
.formItems .OTP-C {
  background: transparent;
  width: 3em!important;
  height: 50px;
  border: 1.5px solid #8b1026;
  border-radius: 6px;
  margin: 10px;
  text-align: center;
  font-family: 'SF Pro Display';
  font-size: 16px;
  line-height: 19px;
}
.formItems .OTP-C:focus {
  border: 1px solid #A3A3A3 !important;
}

/* .formItems .OTP-C:last-child {
  border: 1px solid gray;
} */
.info_text {
  margin-top: 30px;
}
.info_text a {
  text-align: center;
  font-size: 18px;
  text-decoration: underline;
  color: #574558;
}

/* ============================================Waiting list================== */

.mainImgBox {
  position: relative;
  z-index: 1;
}
.mainImgBox img {
  z-index: 9999;
}

.maincontentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a.web-custom-link {
  text-decoration: none;
  line-height: 50px;
}
a.web-custom-link:hover {
  color: black;
}
a.web-custom-link.continue-btn img {
  width: 40px;
  height: 40px;
  margin-left: 25px;

  text-decoration: none;
}




.copyforwaiting {
  position: absolute;
  bottom: 10px;
  color: #92001a;
  font-size: 12px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}
.text-custom-primary {
  color: #92001a;
}

.signUpwait_text {
  margin-bottom: 0;
}


/* ========================================deposit====================== */
.deposit{
  height: 100vh;

}
.SelectCustom {
  width: inherit;
}
.form-deposit form{
  justify-content: center;
}
.align-V-center{
  position: relative;
  height: 100vh;
}

.deposit_main_Sec,.DepositImage_center{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;

}
a.web-custom-link{
  color: #92001A;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;

}


.copyright{
  position: absolute;
  bottom: 0;
  left: 40%;

}
.deposit{
  background: #F6EDE8;
}
.deposit .depositImg{
  width: 400px;
}
.deposit .form-section{
  margin-bottom:30px;

}

.deposit .copyright{
  color: #92001A;
  font-size: 12px;
}
/* ========================================Download btn====================== */
.DownloadContainer{
  width: 100%;
}

.DownloadBtn{
  position: relative;
  background-color: #92001A;
  border-radius:6px;
  margin-top:30px;
  text-align: center;
  font-family: "New York Extra Large";
  color:  #DADADA;
  border: none;
  padding: 8px 20px;
}
.downloadTxt{
  position: absolute;
  font-family: "New York Extra Large";
  top: 6px;
  font-size: 12px;
}
.downloadTxt small{
  font-size: 12px;
  font-weight: 300;
  font-family: "New York Extra Large";
}
.DownloadBtn i{
  font-size: 35px;
  margin-right: 20px;

}
.DownloadBtn span{
  font-family: "New York Extra Large";

  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
}
.downloadMain .DownloadBtn{
  margin:0;

}
.downloadMain .downloadMainImg{
  width: 400px;
}
/* =================================================PopUp =================================== */
.PopupImgBox{
  width: 400px;
  position:absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);


}
.popup-txt{
  color: #92001A;
  text-decoration: underline;

}
.popMainTxt{
  font-family: "New York Extra Large";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  color: #311d35;
  margin-top:30px;
}
a.web-custom-link{
  margin-top:30px;
  font-size: 18px;
  color: #92001A;
  line-height: 10px;

}
.pop-mb-30{
  margin-bottom: 30px;
}
.popup{
  background: white;
}
.static-on-mobile{
  position: relative;
  height: 100vh;
}
.no-trans-mobile{
  width: 100%;
}

.no-trans-mobile{
  position: absolute;
  left:50%;
  top: 50%;
  transform: translate(-50%, -50% );
  text-align: center;
}
.popup_Home{
  max-width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
/* ============================ACcept privacy======================================== */
.AcceptPrivacy .sec_text{
  margin-bottom:30px;
}
.AcceptPrivacy form{
  margin-top: 30px;
}
.AcceptPrivacy .custom-btn-web{
  margin: auto;
  cursor: pointer;
}
.AcceptPrivacy .DownloadBtn{
  text-transform: capitalize;
}
.DownloadBtn i.upload-custom{
  font-size: 18px;
}

.waitingMain .sec_text.m-0{
  margin: 0;
}
.waitingMain .copyForWait{

  position: relative;
  top:105px;
  color: #92001A;
  font-size: 12px;


}
.waitingMain .DownloadBtn{
  text-transform: unset;
  font-family: New York Extra Large;
  font-weight: 400;
  font-size: 18px;
}

.waitingMain a{
  color: #92001A;
  text-decoration: underline;

}
.waitingMain .waitImgBox{
  width: 500px;
}

@media (max-width:1192px) {
  .deposit_main_Sec .main_txt_sign{
    font-size: 20px;
  }
  .deposit_main_Sec{
    padding: 20px;
  }
  .deposit_main_Sec .form-section {
    width: 403px;
    margin-top: 30px;
  }

}


@media (max-width: 992px) {
  .maincontentBox {
    justify-content: unset;
    align-items: unset;
  }

  .deposit_main_Sec .main_txt_sign{
    font-size: 20px;
  }
  .deposit_main{
    width: 100%;
  }
  .deposit-btn{
    display: block;
    margin-top:16px;
    width: 240px;
    margin-left: 0px;
  }

  .deposit_main .form-section form{
    display: block;
  }
  .deposit_main .form-section{
    width: 100%;
  }
  .deposit_main .custom-btn-web{
    position: unset;
    margin-top: 16px;
    bottom: 0;
  }
  .deposit{
    height: 100vh;

  }
  .deposit_main .main_txt_sign.text-center{
    text-align: left!important;
    margin-top:30px;
  }
  .deposit a.signOutLink{
    text-align: center;
  }
  .popupRight a{
    line-height: inherit;
    margin-top: 16px;
  }
  .popupRight{
    text-align: center;
  }
  .popMainTxt{
    font-family: "New York Extra Large";
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    color: #311d35;
    text-align: center!important;
  }

  .static-on-mobile{
    position: static;
    height: auto;
  }

  .no-trans-mobile{
    position: static;
    transform: translate(0, 0);
  }
  .web-logo-hide{
    display: none;
  }
  .no-margin{
    margin: 0;
  }
  .PopupImgBox{
    width: 100%;
  }
  .popup_Home {
    max-width: 100%;
    height: auto;
    background: #fff;
    display:block;
    justify-content:unset;
    align-items: unset;
    flex-direction: column;
    position: static;}
}

@media (max-width: 767px) {
  .main-Section-box {
    display: block;
  }
  .popMainTxt{
    text-align: center!important;
  }
  .web-logo-hide{
    display: none;
  }
  .popup_Home .custm-angle{
    display: none;
  }
  .padding-0{
    margin: 0;
    padding: 0;
  }
  .custom-btn-web{
    margin-left: 0;
  }

  .Sign_Home{
    justify-content: inherit;
    padding-top: 100px;
  }
  .custom-btn-web {
    width: 100%;
  }
  .sec_text {

    margin-bottom: 0px;
  }
  .img-logo {
    display: none;
  }
  .custm-angle {
    display: flex;
  }
  .custm-angle .backBtn{
    margin-right: auto;
  }
  .custm-angle .backBtn i{
    font-size: 30px;
    width: 30px;
  }
  .custm-angle .mobileTitle{
    flex: 1;
    text-align: center;
    font-family: 'SF Pro Display';
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: #4A4A4A;
  }
  .main_txt {
    margin-top: 59px;
    font-size: 26px;
  }
  h2.main_txt {
    margin-top: 0px !important;
  }
  .form-section form {
    display: block;
    width: 100%;
    padding: revert;
  }
  .main_txt {
    width: 82%;
    text-align: left;
  }
  .main_txt span {
    display: inline !important;
  }
  .main_Box {
    width: 100% !important;

  }

  .sec_text {
    text-align: left !important;
  }
  .text-Custom {
    width: 100%;
    /* border: 1.5px solid #92001A; */
  }
  .copy {
    display: none;
  }
  .sec_text {

    margin-bottom: 0px;
  }
  .main_Box{
    padding: 10px;
  }

  .img-box {
    display: none;
  }
  .sign_UpBox {
    padding: 0px 25px;
    margin-top: -190px;
  }
  .main_txt_sign {
    font-size: 24px;
  }
  .main_txt_sign span,
  .signUp_text span {
    display: unset !important;
    text-align: unset !important;
  }
  .signUp_text {
    margin-top: 16px;
    font-size: 16px;
  }

  .continue-btn {
    position: absolute;
    bottom: 10px;
    margin: 0;
  }
  .alrt_txt1 {
    font-size: 16px;
  }

  .info_text a {
    border: none;
  }
  .formItems .OTP-C {
    margin: 3px;
  }

  .waitingMain {
    height: 100%;
  }

  .mainImgBox {
    margin-top: 30px;
    padding: 30px;
  }
  .waitingMain {
    height: 100vh;
  }
  .waitingMain .mainImgBox {
    margin: auto;
  }

  .mainImgBox::before {
    height: 86%;
    left: 29%;
    top: -1%;
  }

  .img-box_left {
    display: none;
  }
  // .main_txt_sign {
  //   /*text-align: left;*/
  // }
  .customForWait {
    position: absolute;
  }
  .img-box_left p {
    margin: 0;
    padding: 0;
  }
  .signUpwait_text {
    margin-bottom: 0;
  }
  .signUpwait_text,
  .signUpwait_text1 {
    text-align: left !important;
  }
  p.signUpwait_text1 {
    margin: 0;
    padding: 0;
  }
  .popMainTxt{
    text-align: center!important;
  }

  .copyforwaiting {
    display: none;
  }

  .maincontentBox {
    justify-content: unset;
    align-items: unset;
  }
  .popupRight a{
    line-height: inherit;
  }

  .popupRight{
    padding: 0px 30px;
  }

  .PopupImgBox{

    max-width: 305px;
    margin:auto;

  }
  .popMainTxt{
    text-align: center!important;
    font-size: 26px;
  }
  .popSecTxt {
    text-align: center!important;
    font-weight: 400;
  }


  .deposit_main_Sec {
    position: static;
    transform: translate(0, 0);
    text-align: center;
    padding: 30px;
  }
  .copyright{
    display: none;
  }


  .static-on-mobile{
    position: static;
    height: auto;
  }

  .no-trans-mobile{
    position: static;
    transform: translate(0, 0);
  }
  .web-logo-hide{
    display: none;
  }
  .AcceptPrivacy{
    padding-top: 316px;
  }
  .AcceptPrivacy .main_txt{
    width: 100%;
  }
  .AcceptPrivacy .MobileText {
    display: inline;
  }
  .AcceptPrivacy .custom-btn-web {
    margin-top:35px;
    position: static;
  }
  .invitation .custom-btn-web{
    width: 300px;

  }
  .invitation {
    display: block
  ;
    padding-top: 339px;
  }

  .invitation .custom-btn-web{
    position: absolute;
    bottom: -193!important;
    margin-left: 90px;
  }
  .invitation h2{
    margin-left: 60px;
  }
  .invitation form{
    padding-left: 0px!important;
    align-items: center;
  }

  .deposit .Sign_Home{
    padding-top: 1px;
    height: 100%;
  }
  .deposit .depositImg {
    width: 100%;
    padding: 0;
    margin:0;
  }
  .deposit .sec_text{
    font-size: 16px;

  }

  .deposit h2.main_txt {
    margin-top: 30px !important;
  }
  .deposit_main_Sec{
    width: 100%;
  }
  .deposit_main_Sec .form-section {
    width: 338px;
  }
}


@media (max-width: 575px) {
  .main_Box {
    width: 100% !important;
  }
  .deposit_main_Sec{
    padding: 0;
    margin: 0;
  }
  .pad-m-0{
    padding: 0;
    margin: 0;
  }
  .deposit_main_Sec .main_txt_sign {
    font-size: 27px;
  }
  .main_txt_sign,.signUpwait_text {
    padding-left: 10px;
    padding-right: 10px;

  }
  .text-Custom{

    margin-right: 10px;
  }
  .align-V-center{
    margin: 0;
    padding: 0;
  }
  .form-section {
    width: 100%;
    margin-top: 30px;
    padding: 0 ;
  }

  .main_txt.d-sm-inline {
    margin-left: -6%;
  }
  .waitingMain .mainImgBox {
    padding: 0px 0px 30px;
    padding-bottom: 30px;
  }

  .img-column {
    padding: 0px;
  }
  .mainImgBox::before {
    height: 80%;
    left: 27%;
    top: 3%;
  }
  .deposit{
    padding: 10px;

  }
  .deposit_main .custom-btn-web{
    width: 250px;
  }
  .PopupImgBox {
    max-width: 100%;
    margin:auto;
  }
  .popupRight{
    text-align: center;
  }
  .mainImgBox {
    width: 100%;
  }
  .static-on-mobile{
    position: static!important;
    height: auto;
  }

  .no-trans-mobile{
    position: static!important;
    transform: translate(0, 0);
    margin-top: 0;
  }

  .PopupContentBox{
    margin-top: 30px;
  }
  .popup_Home .PopupImgBox{
    width: 100%;
  }
  .no-trans-mobile{

    text-align: center;
  }
  .popup_Home .MobileText{
    display: inline;

  }
  .margin-t-30{
    margin-top: 30px;
  }

  .popup_Home .main_txt{
    width: 100%;
  }
  .popup_Home .sec_text{
    text-align: center!important;
    margin-bottom: 30px;
  }
  .no-trans-mobile h2.main_txt{
    text-align: center;
    margin-top: 16px;
  }
  .popup_Home .PopupContentBox{
    padding: 0px 30px 20px ;
  }
  .popup_Home .col-md-6{
    padding-right: 0px!important;

  }

  .invitation .custom-btn-web{
    margin:19px;
  }
  .invitation h2{
    padding-left: 0px;
    margin-left: 10px;
  }
  .depositVip .depositVip {
    height:100%;
  }
  .depositVip a.web-custom-link{
    display:block;
  }
  .depositVip .align-V-center{

    height: auto;
  }
  .depositVip .mainImgBox{
    padding: 0px 0px 30px 0px;
  }

  .depositVip .Sign_Home{
    height: auto;
    padding: 0;
  }
  .depositVip .custom-btn-web{
    position: static!important;
    margin:30px auto 0;
  }
  .waitingMain {
    height: 100%;
    padding:0px;
  }
  .waitingMain .waitImgBox{
    width: 100%;
  }
}
@media (min-width:450px) and (max-width: 560px){

  .popup_Home .PopupImgBox{
    padding: 0;
  }
  .popup_Home .PopupImgBox{
    width: 100%;
  }
  .no-trans-mobile{

    text-align: center;
  }
  .popup_Home .MobileText{
    display: inline;

  }
  .margin-t-30{
    margin-top: 30px;
  }

  .popup_Home .main_txt{
    width: 100%;
  }
  .popup_Home .sec_text{
    text-align: center!important;
    margin-bottom: 30px;
  }
  .no-trans-mobile h2.main_txt{
    text-align: center;

    margin-top: 16px;
  }
  .popup_Home .PopupContentBox{
    padding: 10px 75px 20px;

  }
  .popup_Home .col-md-6{
    padding-right: 0px!important;

  }
  .PopupImgBox .center-block {
    display: unset;
    margin-right: 0px;
    margin-left: auto;
    width: 100%;
  }
  .depositVip .depositVip {
    height:100%;
  }
  .depositVip a.web-custom-link{
    display: block;
  }
  .depositVip .custom-btn-web{
    position: static!important;
    margin:30px auto 0;
  }

}

@media (max-width: 411px) {

  .Sign_Home {
    width: 100% !important;
    height: 100vh;
  }
  .main_Box {
    width: 100% !important;
  }
  .img-logo {
    display: none;
  }
  .form-section form {
    display: block;
  }
  .main_txt {
    font-size: 26px;
  }
  .main_Box {
    width: 100%;
  }

  .img-column {
    margin: 0;
  }
  .maincontentBox {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .mainImgBox {
    margin-top: -126px;
  }
  .waitingMain{
    padding: 30px 0;
  }
  .waitingMain .mainImgBox {
    padding:0;
    padding-bottom:30px;
  }
  .waitingMain .waitImgBox{
    width: 100%;
  }
  .waitingMain .copyForWait{
    display: none;
    margin-bottom: 30px;
  }

  .PopupImgBox {
    padding-top: 117px;
    margin:auto;
  }
  .popup_Home .PopupImgBox{
    padding: 0;
  }
  .popup_Home .PopupImgBox{
    width: 100%;
  }
  .no-trans-mobile{

    text-align: center;
  }
  .popup_Home .MobileText{
    display: inline;

  }
  .margin-t-30{
    margin-top: 30px;
  }

  .popup_Home .main_txt{
    width: 100%;
  }
  .popup_Home .sec_text{
    text-align: center!important;
    margin-bottom: 30px;
  }
  .no-trans-mobile h2.main_txt{
    text-align: center;
    margin-top: 16px;
  }
  .popup_Home .PopupContentBox{
    padding: 0px 30px 20px;
  }
  .popup_Home .col-md-6{
    padding-right: 0px!important;

  }
  .invitation .custom-btn-web{
    position:absolute;
    bottom: -153!important;
  }
  .invitation {
    display: block;
    padding-top: 288px;
  }
  .invitation .custom-btn-web {
    width: 277px;
  }
  .depositVip .depositVip {
    height:100%;
  }
  .depositVip a.web-custom-link{
    display: none;
  }
  .depositVip .custom-btn-web{
    position: static!important;
    margin:30px auto 0;
  }
  .depositVip .mainImgBox{
    margin-top:0px;
  }

}
@media (max-width: 360px) {
  .invitation .custom-btn-web{
    position:absolute;
    bottom: -153!important;
  }
  .popup_Home .PopupImgBox{
    padding: 0;
  }
  .popup_Home .PopupImgBox{
    width: 100%;
  }
  .no-trans-mobile{

    text-align: center;
  }
  .popup_Home .MobileText{
    display: inline;

  }
  .margin-t-30{
    margin-top: 30px;
  }

  .popup_Home .main_txt{
    width: 100%;
  }
  .popup_Home .sec_text{
    text-align: center!important;
    margin-bottom: 30px;
  }
  .no-trans-mobile h2.main_txt{
    text-align: center;
    margin-top: 16px;
  }
  .popup_Home .PopupContentBox{
    padding: 0px 20px 20px 0px;
  }
  .popup_Home .col-md-6{
    padding-right: 0px!important;

  }
}
@media (max-width: 320px) {
  .formItems .OTP-C {
    margin: 1px;
  }
  .deposit_main .custom-btn-web{
    width: 202px;
  }
  .custom-btn-web{
    margin-bottom: 16px;
  }
  .AcceptPrivacy {
    padding-top: 291px;
  }
  .invitation .custom-btn-web {
    width: 210px;
  }
  .invitation{
    margin-top: 0px;
  }
  .invitation h2{
    margin-top: 0px;
    font-size: 22px;
  }
  .invitation {
    display: block;
    padding-top: 299px;
  }

  .deposit .depositImg {
    width: 100%;}
}

@media (max-width: 301px) {
  .formItems .OTP-C {
    margin: 0px;
  }
  .deposit_main .custom-btn-web{
    width: 160px;
  }
}

.accept-red-color {
  color: #92001a;
  font-size: 26px !important;
  cursor: pointer;
  vertical-align: middle;
  border: 0;
  margin-right:4px;
}

.waiting-list-back-btn {
  color: #92001a;
  font-size: 36px !important;
}

/* ===================================SUbscription=============== */

.Subscription{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.Subscription_cust{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background: rgba(238, 221, 211, 0.6);
  padding: 15px;
}

.SubsBox{
  background-color: #fff;
  padding: 100px 75px;
  border-radius: 6px;
  max-width: 530px;
  width: 100%;
}
.SubsBox ul li {
  color: #000C38;
  font-family: 'SF Pro Display';
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 32px;
}

.SubsBox ul li img{
  width: 20px;
  height: 20px;
  margin-right: 12px;
  position: absolute;
  top: 2px;
  left: 0;
}
.cust_subs_heading {
  color: #4A4A4A;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 34px;
  line-height: 30px;
  margin-bottom: 20px;
}
.subs_mainBox .cust_btn_group{
  border: 1px solid #871C27;
  border-radius: 6px;
}
.cust_btn_group .btnsubs{
  padding: 11px 24px;
  display: inline-block;
  font-family: 'New York Medium';
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #A3A3A3;
  border-radius: 6px;
  cursor: pointer;
}
.cust_btn_group .btnsubs.active{
  color: #ffffff;
  background: #871C27;
}
.subs_mainBox{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}
.Price_group{
  margin-top: 30px;
  width: 100%;
}
.priceBox{
  height: 100%;
  border: 2px solid #F5EAE5;
  padding: 32px 19px 19px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.priceBox.active{
  border:2px solid #871C27;
}
.priceBox .subs_discount{
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.08px;
  background: #F5EAE5;
  border-radius: 4px;
  padding: 3px 10px;
  color: #000000;
  position: absolute;
  top: -14px;
}
.priceBox.active .subs_discount{
  background: #871C27;
  color: #ffffff;
}
.month_subs{
  color: #A3A3A3;
  font-weight: bolder;

}

.rate_subs{
  color: #311D35;

}
.per_subs{
  color: #311D35;

}

.star_subs{
  margin-top: 10px;
  color: #311D35;
  text-align: center;
  font-weight: 500;

}
.subs_renew{
  color: #574558;
  text-align: center;
  margin: 10px ;
  font-weight: 500;
}

/* ========================================shiping=============== */
.form-section_add{
  width: 100%;
  max-width: 425px;
}
.form-section_add input.form-control{
  margin-top: 16px;
  height: 50px;
}
.form-section_add select{
  height: 50px;
  margin-top: 10px;
}

.subs_rbill{
  color: #574558;
  margin-top: 16px;
}
.shiping_para{
  font-size: 20px;
  color: #000;
  text-align: center;
  padding: 0px 0px;
}
@media (max-width: 767px){
  /*.shiping_para{*/
  /*  margin-top: 100px;*/
  /*}*/
  .Subscription {
    padding-top: 50px;
  }
}
.container_5-fluid{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row_5{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.row_5>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col_5-12{
  flex: 0 0 auto;
  width: 100%;
}
.col_5-6{
  flex: 0 0 auto;
  width: 50%;
}
@media (min-width: 768px){
  .col_5-md-6{
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 992px){
  .col_5-lg-6{
    flex: 0 0 auto;
    width: 50%;
  }
}
.new_copyright{
  font-family: 'SF Pro Display';
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #92001A;
  opacity: 0.6;
}
.mt-auto{
  margin-top: auto;
}
.subsRightBoxWrapper{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
}
.subsRightBox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 372px;
  width: 100%;
}

.Subscription .custom-btn-web {
  width: 100%;
}
a{
  color: unset;
  text-decoration: underline;
}
@media (max-width: 991px) {
  .SubsBox .cust_subs_heading{
    display: none;
  }
  .SubsBox ul li {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .Subscription_cust{
    background: #fff;
    justify-content: flex-start;
    align-items: end;
    height: auto;
    min-height: 0;
  }
  .SubsBox{
    padding: 0px 24px 0px;
  }
  .subs_mainBox{
    height: auto;
    min-height: 0;
  }
}
.libi-checkbox-wrapper{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'SF Pro Display';
  font-size: 16px;
  color: #574558;
}
.libi-checkbox-wrapper input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 2px solid #a3a3a3;
  border-radius: 50%;
}
.libi-checkbox-wrapper input:checked ~ .checkmark {
  border: 2px solid #92001A;
}
.libi-checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}
.libi-checkbox-wrapper .checkmark:after {
  content: '';
  display: none;
  position: absolute;
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #92001A;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-section_add .select-search__value{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 16px;
  border: 1px solid #E3E3E3;
}
.form-section_add .select-search__value input{
  margin: 0;
  padding: 0;
}
.confirm_title{
  margin-bottom: 12px;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #311D35;
  display: flex;
  padding: 0 18px;
}
.confirm_title .confirm_title_action{
  margin-left: auto;
  font-family: 'SF Pro Display';
  font-size: 18px;
  line-height: 21px;
  color: #A3A3A3;
}
.confirm_data{
  background: #FBF1EB;
  border-radius: 12px;
  font-family: 'SF Pro Display';
  font-size: 16px;
  line-height: 19px;
  color: #574558;
  padding: 18px;
  margin-bottom: 16px;
}
.confirm_data_title{
  font-family: 'SF Pro Display';
  font-size: 18px;
  line-height: 19px;
  color: #311D35;
  margin-bottom: 10px;
}
.align_right{
  float: right;
}
.confirm_data .total{
  font-weight: bold;
  font-size: 16px;
}
.confirm_notif{
  display: flex;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #311D35;
  align-items: center;
}
.confirm_notif span{
  margin-left: 16px;
}
.skincareTabs{
  display: flex;
  font-family: 'New York Medium';
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #A3A3A3;
  padding: 16px 20px;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #A3A3A3;
}
.skincareTabs.another{
  background: #F9F9F9;
  border-radius: 4px;
  border: 0;
  padding: 4px;
  color: #4F4F4F;
  font-weight: normal;
}
.skincareTab.active{
  color: #92001A;
  text-decoration: underline;
}
.skincareTabs.another .skincareTab{
  padding: 7px 28px;
}
.skincareTabs.another .skincareTab.active{
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: 600;
  text-decoration: none;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.06);
}
.skincareContent{
  margin-top: 16px;
  flex: 1;
}
.skincareContent iframe{
  width: 100%;
  height: 100%;
  border: 0;
}
@media (max-width: 767px) {
  .skincareTabs{
    padding: 16px 0px;
  }
}
@media (max-width: 767px) {
  .Subscription_cust img {
    height: 50vh;
    object-fit: contain;
    object-position: right;
  }
}
@media (max-width: 992px) {
  .skincareTabs.another .skincareTab{
    padding: 7px 6px;
    font-size: 14px;
    line-height: 16px;
  }
}
.key_with_label_wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.key_with_label_wrapper .key_label{
  position: absolute;
  width: 36%;
  top: 28.5%;
  left: 11%;
}
.key_with_label_wrapper .key_label_text{
  position: absolute;
  width: 40%;
  top: 37.5%;
  left: 11%;
  transform: rotate(-55deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.key_bg_with_label_wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.key_bg_with_label_wrapper .key_label{
  position: absolute;
  width: 16%;
  top: 35.5%;
  left: 25%;
}
.key_bg_with_label_wrapper .key_label_text{
  position: absolute;
  width: 17%;
  top: 43%;
  left: 25%;
  transform: rotate(-55deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.downloadLabel{
  font-family: 'SF Pro Display';
  font-size: 13px;
  max-width: 210px;
  margin-left: 15px;
}
.horizontalRadio{
  display: flex;
  border-top: 1px solid rgba(0,0,0,0.15);
  border-bottom: 1px solid rgba(0,0,0,0.15);
  padding: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}
.horizontalRadioInputs input{
  display: none;
}
.horizontalRadioInputs{
  display: flex;
  flex-wrap: wrap;
}
.horizontalRadioInputs label{
  flex: 1;
  padding: 3px 12px;
  cursor: pointer;
}
.horizontalRadioInputs input[type=radio]:checked + label{
  background: #92001A;
  border-radius: 200px;
  color: #ffffff;
}
.scroll_wrapper{
  max-height: 200px;
  overflow-y: auto;
  margin: 15px 0;
  padding: 5px;
  text-align: left;
}

/* TypeForm */
.typeFormSlide{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.typeFormSlide .question{
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  align-self: flex-start;
}
.typeFormSlide .questionWrapper{
  max-width: 720px;
  margin: 0 auto;
  max-height: 100%;
  width: 100%;
}
.typeFormSlide .questionWrapperWrapper{
  padding: 40px 0 70px;
}
.typeFormSlide .action{
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0,0,0,0.1) 0 -1px;
}
.typeFormSlide .questionMainImage{
  width: 250px;
  max-width: 100%;
  margin: 0 auto 40px;
  display: block;
}
.typeFormSlide .big_image_without_popup{
  width: calc(100% + 80px);
  height: 50vh;
  margin: 0 -40px 40px;
  display: none;
  background-position: center;
  background-size: 130%;
}
@media (max-width: 767px) {
  .typeFormSlide .questionMainImage.big{
    display: none;
  }
  .typeFormSlide .big_image_without_popup.big{
    display: block;
  }
}
@media (min-width: 768px) {
  .typeFormSlide .questionMainImage.big{
    display: block;
  }
  .typeFormSlide .big_image_without_popup.big{
    display: none;
  }
}
.typeFormSlide .show_popup{
  width: calc(100% + 80px);
  max-width: none;
  margin: 0 -40px 40px;
}
.typeFormSlide .popup_image{
  cursor: pointer;
}
.typeFormSlide .questionTitle{
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.typeFormSlide .questionDescription{
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  opacity: 0.7;
}
.typeFormSlide .actionButton{
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0,0,0,0.1) 0px 3px 12px 0px;
  padding: 8px 18px;
  min-height: 48px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 24px;
  line-height: 32px;
  background: var(--button-color);
}
.typeFormSlide .actionButton:hover{
  opacity: 0.8;
}
.typeFormSlide.split{
  flex-direction: row;
  flex-wrap: wrap;
}
.typeFormSlide .rightSidebar{
  display: none;
}
.typeFormSlide.split .question,
.typeFormSlide.split .rightSidebar{
  display: flex;
  width: 50%;
  flex: 0 0 auto;
}
.typeFormSlide.split .questionMainImage{
  display: none;
}
.rightSidebar .backgroundImage{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.typeFormSlide .typeFormDropdown__input,
.typeFormSlide .inputText{
  box-shadow: var(--answer-color) 0px 1px;
  display: block;
  width: 100%;
  min-height: 40px;
  color: var(--answer-color);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 30px;
  line-height: unset;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
  background-color: transparent !important;
}
.typeFormSlide .typeFormDropdown__input:focus,
.typeFormSlide .inputText:focus{
  box-shadow: var(--answer-color) 0px 2px;
}
.TypeForm .PhoneInputInput::placeholder,
.typeFormMultiple .typeFormMultipleItem input::placeholder,
.typeFormSlide .typeFormDropdown__input::placeholder,
.typeFormSlide .inputText::placeholder,
.typeFormSlide input::placeholder{
  color: #afaaaa;
}
.typeFormSlide *{
  appearance: none !important;
}
.typeFormSlide .answerWrapper{
  margin-top: 32px;
}
.typeFormSlide .answerButton{
  position: relative;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  box-shadow: rgba(0,0,0,0.1) 0 3px 12px 0;
  padding: 6px 14px;
  min-height: 40px;
  background-color: var(--button-color);
  color: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  font-size: 20px;
  line-height: 28px;
}
.typeFormSlide .answerButton svg{
  fill: #ffffff;
  margin-left: 8px;
}
.typeFormSlide .answerButton:hover{
  opacity: 0.8;
}
.typeFormSlide .questionNumber{
  color: var(--answer-color);
  margin-right: 12px;
}
.typeFormSlide .typeFormDropdown__select{
  max-height: 200px;
  overflow-y: auto;
}
.typeFormSlide .typeFormDropdown__options{
  padding: 8px 0 16px;
  list-style: none;
}
.typeFormSlide .typeFormDropdown__row{
  margin-bottom: 4px;
}
.typeFormSlide .typeFormDropdown__option{
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  background-color: rgb(224, 192, 185);
  box-shadow: var(--answer-color) 0px 0px 0px 1px inset;
  color: var(--answer-color);
  max-width: 100%;
  min-width: 75px;
  min-height: 40px;
  outline: 0px;
  padding: 4px 4px 4px 8px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  font-size: 20px;
  line-height: 28px;
}
.typeFormSlide .typeFormDropdown__option:hover{
  background-color: rgb(207, 149, 150);
}
.typeFormMultiple svg{
  display: none;
  margin: 0px 12px 0 auto;
  padding-left: 20px;
  fill: var(--answer-color);
  min-width: 36px;
}
.typeFormMultipleItem.selected svg{
  display: inline;
}
.typeFormMultiple .typeFormMultipleItem{
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  background-color: rgb(224, 192, 185);
  box-shadow: var(--answer-color) 0px 0px 0px 1px inset;
  color: var(--answer-color);
  max-width: 100%;
  min-width: 75px;
  min-height: 40px;
  outline: 0px;
  padding: 4px 4px 4px 8px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.typeFormMultiple .typeFormMultipleItem.selected{
  box-shadow: var(--answer-color) 0px 0px 0px 2px inset;
}
.typeFormMultiple .typeFormMultipleItem:hover{
  background-color: rgb(207, 149, 150);
}
.typeFormMultiple .typeFormMultipleItem input{
  border: none;
  background-color: transparent;
  padding: 0px;
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  min-height: 28px;
}
.typeFormDate{
  display: flex;
  align-items: end;
  color: var(--answer-color);
}
.typeFormDate .typeFormDateItem label{
  font-weight: unset;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}
.typeFormDate .typeFormDateItem input{
  box-shadow: var(--answer-color) 0px 1px;
  display: block;
  color: var(--answer-color);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 30px;
  line-height: 24px;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
  background-color: transparent;
  width: 2.2ch;
}
.typeFormDate .typeFormDateItem input:focus{
  box-shadow: var(--answer-color) 0px 2px;
}
.typeFormDate .typeFormDateItem.year input{
  width: 4.4ch;
}
.typeFormDate .typeFormDateItem .separator{
  display: block;
  font-size: 30px;
  line-height: 38px;
  min-width: 45px;
  text-align: center;
  margin-bottom: 8px;
}
.typeFormFileUploader{
  border: 1px dashed var(--answer-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  border-radius: 3px;
  cursor: pointer;
}
.typeFormFileUploader .backgroundUpload{
  position: absolute;
  top: 0;
  left: 0;
  background: var(--answer-color);
  opacity: 0.1;
  width: 100%;
  height: 100%;
}
.typeFormFileUploader:hover .backgroundUpload{
  opacity: 0.3;
}
.typeFormFileUploader .fileLabel{
  font-size: 14px;
  line-height: 20px;
  color: var(--answer-color);
  margin-top: 16px;
}
.typeFormFileUploader .descr{
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
}
.typeFormFileUploader input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}
.typeFormFileUploader .previewUpload{
  height: 200px;
  width: auto;
  z-index: 1;
}
.TypeForm .PhoneInput{
  max-width: 450px;
}
.TypeForm .PhoneInputCountry{
  margin-right: 16px;
  box-shadow: var(--answer-color) 0px 1px;
  padding: 0px 0px 8px;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
}
.TypeForm .PhoneInputCountryIcon{
  width: 30px;
  height: 20px;
}
.TypeForm .PhoneInputCountrySelectArrow{
  width: 9px;
  height: 9px;
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-color: var(--answer-color);
  opacity: 1;
  margin-left: 8px;
}
.TypeForm .PhoneInputInput{
  box-shadow: var(--answer-color) 0px 1px;
  display: block;
  color: var(--answer-color);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  font-size: 30px;
  line-height: 24px;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
  background-color: transparent;
}
.TypeForm .PhoneInputInput:focus{
  box-shadow: var(--answer-color) 0px 2px;
}
.TypeFormRating > span > i{
  font-style: normal;
  color: var(--answer-color);
  font-size: 45px;
  padding-right: 3px;
}

@media (max-width: 600px) {
  .typeFormSlide .questionTitle{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .typeFormSlide .questionDescription{
    font-size: 16px;
    line-height: 24px;
  }
  .typeFormSlide .actionButton{
    font-size: 20px;
    line-height: 28px;
    width: 100%;
  }
  .typeFormDate .typeFormDateItem input,
  .TypeForm .PhoneInputInput,
  .typeFormSlide .typeFormDropdown__input,
  .typeFormSlide .inputText{
    font-size: 24px;
  }
  .typeFormSlide .answerButton{
    font-size: 16px;
    line-height: 24px;
  }
  .typeFormSlide .questionNumber{
    margin-right: 8px;
  }
  .typeFormSlide .typeFormDropdown__option{
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 1024px) {
  .typeFormSlide.split{
    flex-direction: column-reverse;
  }
  .typeFormSlide.split .rightSidebar{
    width: 100%;
    height: 40vh;
  }
  .typeFormSlide.split .question{
    width: 100%;
    height: 60vh;
  }
}

/*=========================== welcome screen =================*/
.typeFormWelcome {
  min-height: 100vh;
  padding: 77px 135px 135px 94px;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 480px) {
    padding: 50px 20px;
  }

  &Main {
    max-width: 45%;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }

    p {
      color:#746F6C;
      font-size: 16px;
      line-height: 20px;
      font-family: 'Montserrat';
    }

    ul {
      list-style: none;
      margin: 0 0 20px;
      padding: 0;

      li {
        color: #92001A;
        font-size: 16px;
        line-height: 20px;
        font-family: 'Montserrat';
        font-style: italic;
        margin: 0 0 20px;
      }
    }
  }

  &Image {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    padding-right: 135px;

    @media screen and (max-width: 1280px) {
      padding-right: 50px;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &Logo {
    display: inline-block;
    margin-bottom: 48px;
    cursor: pointer;

    img {
      height: 74px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &Button {
    width: 258px;
    height: 47px;
    background: #92001A;
    border-radius: 6px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    line-height: 47px;
    color: #FFFFFF;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-top: 12px;
    transition: .15s;

    &:hover {
      opacity: 0.8;
    }
  }
}


// ======================== upload photos ==========================
.UploadPhotos {
  &Wrapper {
    background: rgb(233, 213, 203);
    position: relative;
    min-height: 100vh;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
    }

    p {
      font-family: 'Montserrat';
      color: #746F6C;
      font-size: 35px;
      font-weight: 500;
      line-height: 54px;
      margin: 0 0 50px;

      @media screen and (max-width: 480px) {
        font-size: 20px;
        line-height: 135%;
        margin: 0 0 30px;
      }
    }
  }

  &Main {
    max-width: 50%;
    padding: 70px 20px 70px 80px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      padding: 50px;
    }
    @media screen and (max-width: 480px) {
      padding: 40px 20px;
    }
  }

  &Logo {
    display: inline-block;
    margin-bottom: 158px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      display: none;
    }

    img {
      height: 94px;
      max-width: 100%;
      object-fit: cover;
    }
  }

  &Input {
    max-width: 720px;
    margin-right: 60px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &Error {
    margin: 20px 0 0;
    font-size: 14px;
    color: var(--answer-color)
  }

  &Button {
    margin-top: 30px;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &Img {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    max-width: 50%;

    @media screen and (max-width: 1024px) {
      position: unset;
      height: unset;
      max-width: 100%;
      width: 100%;
    }
  }
}
/* END TypeForm */