.dataTables_wrapper .dataTables_filter input {
    border: 1px solid #aaa !important;
}
canvas{
    max-width: 100%;
    max-height: 100%;
}
.treatment-remove {
    cursor: pointer;
    padding-left: 15px;
}