.shipment-danger {
    background-color:#FFE8E8!important;
}

.deleted-shipment {
    background-color: gray!important;
}

@media print {
    body * {
      visibility: hidden;
    }
    #divToPrint, #divToPrint * {
      visibility: visible;
    }
    #divToPrint .btn {
        visibility: hidden;
      }
    #divToPrint {
      position: absolute;
      left: 0;
      top: 0;
    }
    #divToPrint.print_product{
        top: 1000px;
        display: none;
    }
    @page { margin: 0; }
    body  { margin: 1.6cm; }
  }

.ready-shipment-btn {
  margin: -4px 0 0 136px;
}

.shipment-delete-btn {
  margin: 2px 15px;
}

.document-upload {
  margin-top: 24px;
}


.products-table {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
}
.products-table th,
.products-table td {
  padding: 8px 12px;
  border: 1px solid #ccc;
  font-size: 16px;
  line-height: 19px;
}