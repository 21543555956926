@import url('https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css');
.d-flex{
  display: flex;
}
.ml-auto{
  margin-left: auto;
}
.far{
  font-family: FontAwesome;
  font-style: normal;
}